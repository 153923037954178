import { Breakpoints, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: ${Spacing.M}px;
  white-space: pre-line;
  @media (max-width: ${Breakpoints.XS}) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const WrapperTopSections = styled(Stack)`
  gap: ${Spacing.M}px;
  @media (max-width: ${Breakpoints.XS}) {
    flex-direction: row;
    gap: ${Spacing.XS}px;
    justify-content: space-between;
  }
`;

export const WrapperLocation = styled(Stack).attrs({
  direction: "column",
  gap: "XS",
})`
  min-width: 0;
`;

export const TextBreak = styled(Text).attrs({
  bold: true,
})`
  word-break: break-all;
`;

import { Box, Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

export const ItemsWrapper = styled(Stack).attrs({
  direction: "column",
})`
  padding: 40px;
  height: 100%;

  @media (max-width: ${Breakpoints.XL}) {
    padding: ${Spacing.M}px;
  }
  @media (max-width: ${Breakpoints.S}) {
    padding: ${Spacing.S}px;
  }
`;

interface ItemContainerProps {
  selected?: boolean;
}

export const ItemContainer = styled(Stack).attrs({
  direction: "column",
  mr: "XS",
  p: "S",
})<ItemContainerProps>`
  background-color: ${Palette.Navy750};
  border-radius: 4px;
  text-transform: capitalize;
  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${Palette.Navy650};
    }
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: ${Palette.Navy650};
    `};
`;

export const ItemParameters = styled(Stack).attrs({
  flex: "1",
  mb: "S",
})`
  flex-basis: max-content;

  @media (max-width: ${Breakpoints.M}) {
    flex-direction: column;
  }
`;

interface ParameterBoxProps {
  $small: boolean;
  $medium: boolean;
  $large: boolean;
  $defWidth: boolean;
  $item: boolean;
}

export const ParameterBox = styled(Stack).attrs({
  direction: "column",
})<ParameterBoxProps>`
  padding: 0 ${Spacing.S}px 0 ${Spacing.S}px;
  border-right: 1px solid ${Palette.Navy100};
  gap: ${Spacing.XS}px;
  width: ${({ $small, $medium, $large, $defWidth = false }) => {
    const sizes = [$small && "160px", $medium && "200px", $large && "320px", $defWidth && "260px"];
    return sizes.find((size) => !!size) ?? "100%";
  }};
  overflow: hidden;

  :nth-child(n) {
    overflow-wrap: break-word;
  }

  :first-child {
    padding-left: 0px;
  }

  :last-child {
    padding-right: 0px;
    border: 0px;
  }

  @media (max-width: ${Breakpoints.M}) {
    width: 100%;
    gap: 0px;
    border-right: 1px solid ${Palette.Navy100};
    :nth-child(n) {
      border-right: ${({ $item }) => ($item ? `0px` : `1px solid ${Palette.Navy100}`)};
      padding-left: ${({ $item }) => ($item ? 0 : Spacing.S)}px;
      padding-bottom: ${({ $item }) => ($item ? Spacing.XS : 0)}px;
    }
    :first-child {
      padding-left: 0px;
    }
    :last-child {
      padding-right: 0px;
      border: 0;
    }
  }
`;

export const ItemTags = styled(Stack).attrs({
  alignItems: "center",
  flexWrap: "wrap",
  gap: "XS",
})`
  width: 100%;
  grid-column: 2 / 6;
`;

export const Buttons = styled(Stack).attrs({
  alignItems: "center",
  gap: "L",
})`
  height: ${Spacing.XL}px;

  @media (max-width: ${Breakpoints.M}) {
    margin-top: ${Spacing.S}px;
    gap: ${Spacing.XS}px;
    width: 100%;
    justify-content: flex-end;
    flex: 1;
  }
`;

export const ParametersWrapper = styled(Stack).attrs({
  direction: "column",
  flex: "1",
})`
  overflow: hidden;
  max-width: 100%;
`;

export const ItemOthers = styled(Stack)`
  align-items: flex-end;

  @media (max-width: ${Breakpoints.M}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ButtonsBox = styled(Stack)`
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: ${Breakpoints.M}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const LoadingWrapper = styled(Stack).attrs({
  justifyContent: "center",
  alignItems: "center",
})``;

export const CenteredWrapper = styled(Stack).attrs({
  justifyContent: "center",
  alignItems: "center",
  flex: "1",
})``;

export const DeleteButtonWrapper = styled(Stack).attrs({
  alignItems: "center",
})`
  height: 100%; // TODO: ???
`;

export const DataBox = styled(Box)`
  border-left: 1px solid ${Palette.Navy100};
  width: 150px;
  overflow: hidden;
`;

import { ButtonIcon } from "@secuis/ccp-react-components";
import React from "react";
import { Container } from "./FiltersButton.styles";
import { useAppSelector } from "../../../../store";
import { selectToolbar } from "../../../../store/toolbar/ToolbarSelectors";
import { useToolbar } from "../ToolbarHooks";

export const FiltersButton = () => {
  const { isOpen: isExpanded } = useAppSelector(selectToolbar);
  const { handleExpand: toggleExpanded } = useToolbar();
  
  return (
    <Container data-testid="filter-button" onClick={() => toggleExpanded(!isExpanded)}>
      <ButtonIcon icon={isExpanded ? "Close" : "Filter"} iconSize="L" mode="stateless" />
    </Container>
  );
};

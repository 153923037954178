import { Box, Button, ButtonText, DropdownCheckbox, Separator, Text } from "@secuis/ccp-react-components";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateNavigator } from "../../../DatePicker/DateNavigator";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { DropdownWrapper, DropdownWrapperFixed } from "./FiltersContent.styles";
import { format } from "date-fns";
import { ToolbarContentWrapper, ToolbarFooter, ToolbarFooterWrapper } from "../Toolbar.styles";
import { sendoutsActions } from "../../../../store/sendouts/SendoutsSlice";
import { selectSendoutsFilters } from "../../../../store/sendouts/SendoutsSelectors";
import { sendoutsApi } from "../../../../store/sendouts/SendoutsApi";
import { SENDOUT_STATUS, SENDOUT_TYPE } from "../../../../models/SendoutModel";
import { useToolbar } from "../ToolbarHooks";

export const SendoutsFiltersContent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectSendoutsFilters);
  const [isChanged, setIsChanged] = useState(false);
  const [offset, setOffset] = useState(0);
  const didMount = useRef(false);
  const dateRef = useRef(null);
  const { isFilterSelectorExpanded, setIsFilterSelectorExpanded } = useToolbar();
  
  useEffect(() => {
    if (didMount.current) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
      didMount.current = true;
    }
  }, [filters]);

  const onExpandDatepickerChange = (ex: boolean) => {
    const { top } = dateRef.current.getBoundingClientRect();
    ex && setOffset(top);
    setIsFilterSelectorExpanded(ex);
  };

  const applyFilters = () => {
    dispatch(sendoutsApi.endpoints.getSendouts.initiate({ page: 1, filters }, { forceRefetch: true }));
    setIsChanged(false);
  };

  const onResetFilters = () => {
    didMount.current = false;
    dispatch(sendoutsActions.resetFilters());
    dispatch(sendoutsApi.endpoints.getSendouts.initiate({ page: 1 }, { forceRefetch: true }));
  };

  return (
    <>
      <ToolbarContentWrapper $isFilterSelectorExpanded={isFilterSelectorExpanded} >
        <Separator />
        <DropdownWrapperFixed>
          <Box pb="S" pt="S">
            <Text bold>{t("Briefs.dropdown.createSendout")}</Text>
          </Box>
          <DropdownCheckbox
            onExpandChange={setIsFilterSelectorExpanded}
            onChange={(val) => dispatch(sendoutsActions.setFilter({ types: val }))}
            options={Object.keys(SENDOUT_TYPE).map((type) => ({ value: type, title: t(`reports.${type}.title`) }))}
            sheetCancelLabel="Cancel"
            values={filters.types || []}
            placeholder={t("Filter.placeholder.all")}
          />
        </DropdownWrapperFixed>
        <DropdownWrapperFixed>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.status")}</Text>
          </Box>
          <DropdownCheckbox
            onExpandChange={setIsFilterSelectorExpanded}
            onChange={(val) => dispatch(sendoutsActions.setFilter({ statuses: val }))}
            options={Object.keys(SENDOUT_STATUS).map((status) => ({ value: status, title: t(`Filter.statuses.${status}`) }))}
            sheetCancelLabel="Cancel"
            values={filters.statuses || []}
            placeholder={t("Filter.placeholder.all")}
          />
        </DropdownWrapperFixed>
        <DropdownWrapper ref={dateRef}>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.date")}</Text>
          </Box>
          <DateNavigator
            offset={offset}
            onExpandChange={onExpandDatepickerChange}
            startDate={filters?.start_date ? new Date(filters.start_date) : null}
            endDate={filters?.end_date ? new Date(filters.end_date) : null}
            placeholder={t("CreateReport.schedule.selectDate")}
            setStartDate={(val) => dispatch(sendoutsActions.setFilter({ start_date: !!val ? format(val, "yyyy-MM-dd") : "" }))}
            setEndDate={(val) => dispatch(sendoutsActions.setFilter({ end_date: !!val ? format(val, "yyyy-MM-dd") : "" }))}
          />
        </DropdownWrapper>
      </ToolbarContentWrapper>
      <ToolbarFooter>
        <ToolbarFooterWrapper>
          <Button mode="contained" color="accent" disabled={!isChanged} onClick={applyFilters}>
            {t("filter.applyFilter")}
          </Button>
          <ButtonText color="accent" onClick={onResetFilters}>
            {t("Filter.reset")}
          </ButtonText>
        </ToolbarFooterWrapper>
      </ToolbarFooter>
    </>
  );
};

import React, { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toolbarActions } from "../../../store/toolbar/ToolbarSlice";
import { selectToolbar } from "../../../store/toolbar/ToolbarSelectors";
import { FiltersContent } from "./Filters/FiltersContent";
import { useTranslation } from "react-i18next";
import { ItemsFiltersContent } from "./Filters/ItemsFiltersContent";
import { SendoutsFiltersContent } from "./Filters/SendoutsFiltersContent";
import { CollectedBriefs } from "./Collections/CollectedBriefs";

type UseToolbar = {
  isFilterSelectorExpanded: boolean;
  setIsFilterSelectorExpanded: (val: boolean) => void;
  handleExpand: (val: boolean) => void;
  toolbar: {
    check: boolean;
    content: JSX.Element;
    title: string;
  };
};

export const useToolbar = (): UseToolbar => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { contentType, isOpen, storeType } = useAppSelector(selectToolbar);
  const [isFilterSelectorExpanded, setIsFilterSelectorExpanded] = useState<boolean>(false);

  const handleExpand = (value: boolean) => {
    dispatch(toolbarActions.setToolbar({ isOpen: value }));
  };

  const toolbar = useMemo(
    () => ([
      {
        check: contentType === "filters", 
        content: [
            storeType === 'reports' && <FiltersContent />,
            (storeType === "items" || storeType === "briefs") && <ItemsFiltersContent />,
            storeType === 'sendouts' && <SendoutsFiltersContent />
          ].find((content) => content),
        title: t("Filter.title"),
      },
      {
        check: contentType === "collection",
        content: <CollectedBriefs />,
        title: t("Toolbar.collections.title"),
      },
    ].find(({ check }) => check))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [contentType, isOpen, storeType]);

  return {
    isFilterSelectorExpanded,
    setIsFilterSelectorExpanded,
    handleExpand,
    toolbar
  }
}
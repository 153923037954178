import { Box, ButtonCompact, ButtonIcon, Icon, Modal, RadioButton, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { format, getTime, startOfTomorrow } from "date-fns";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NotificationTypes } from "../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../context/notifications/NotificationsContext";
import { useTagsAndRegions } from "../../../hooks/useTagsAndRegions";
import { useUserData } from "../../../hooks/useUserData";
import { REPORT_TYPES } from "../../../models/ReportModel";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectItemsCollection, selectItemsCollectionSelected } from "../../../store/items/ItemsSelectors";
import { itemsActions } from "../../../store/items/ItemsSlice";
import { getEndDate } from "../../../utilities/Item.helpers";
import { getTranslatedTag } from "../../../utilities/tagsUtils";
import { generateReport } from "../../../utilities/userUtils";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { ScheduleTimeComponent } from "../../DatePicker/ScheduleTimeComponent";
import { CreateReportDropdown } from "../../Reports/Create/CreateReportDropdown";
import { DataBox, ItemContainer } from "./styled";
import { useScheduleDate } from "../../../hooks/useScheduleDate";
import { getDateWithoutTimezone } from "../../../utilities/dateUtils";

export const CollectItemsModal = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (v: boolean) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useContext(NotificationsContext);
  const { userToken } = useUserData();
  const { initRegions } = useTagsAndRegions();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(startOfTomorrow());
  const [endDate, setEndDate] = useState<Date | null>(startOfTomorrow());
  const [reportType, setReportType] = useState<string>("");
  const [isEmptyCartModalOpen, setIsEmptyCartModalOpen] = useState<boolean>(false);
  const itemsCollectionSelected = useAppSelector(selectItemsCollectionSelected);
  const collectedItems = useAppSelector(selectItemsCollection);
  const { scheduleDate, setScheduleDate } = useScheduleDate(reportType);
  
  const handleSelectCollecedItem = (event) => {
    dispatch(itemsActions.checkCollectItem({ id: event.target.value, region: event.target.name }));
  };

  const handleEmptyCart = () => {
    setIsEmptyCartModalOpen(false);
    handleCloseModal();
    dispatch(itemsActions.emptyCollection());
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setReportType("");
    setScheduleDate(null);
  };

  const handleSelectReportType = (value: string) => {
    setReportType(value === "PLACEHOLDER" ? "" : value);
  };

  const hideCalendar = () => {
    setIsCalendarOpen(false);
  };

  const showCalendar = () => {
    setIsCalendarOpen(true);
  };

  const handleCreateReport = useCallback(async () => {
    const parmeters = {
      report_type: reportType,
      schedule: scheduleDate,
      item_ids: collectedItems.filter((item) => item.selected).map((item) => item.id),
    };

    newReportRequest(parmeters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, scheduleDate, collectedItems]);

  const newReportRequest = useCallback(
    async (parmeters) => {
      try {
        const newReport = await generateReport(userToken, parmeters);
        if (newReport.report_id) {
          dispatch(itemsActions.emptyCollection());
          const to = {
            pathname: "/createReport",
            search: new URLSearchParams({ type: reportType, id: newReport.report_id }).toString(),
          };
          toast({
            title: t("CreateReport.toast.createReport", {
              type: reportType,
            }),
            type: NotificationTypes.Confirmation,
            icon: "Success",
          });
          navigate(to);
        } else {
          toast({ title: newReport?.detail ? newReport?.detail : t("errors.fetchError.title"), type: NotificationTypes.Error, icon: "Error" });
        }
      } catch (e) {
        toast({ title: e instanceof Error ? e.message : t("CreateReport.toast.failed"), type: NotificationTypes.Error, icon: "Error" });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [reportType, userToken]
  );

  const time = scheduleDate && format(getTime(scheduleDate), "k:mm");

  return (
    <>
      <Modal isOpen={isOpen} size="L" onClose={handleCloseModal} style={{ width: "1280px", maxHeight: "80%" }}>
        <Stack direction="row" marginBottom="L" justifyContent="space-between" alignItems="center">
          <Stack alignItems="center" gap={"XXL"}>
            <Stack direction="row" alignItems="center" gap={"XS"}>
              <ButtonIcon onClick={handleCloseModal} iconSize="L" icon="BackNavigation" mode="stateless" />
              <Text>{t("Items.collection.backToList")}</Text>
            </Stack>
            <ButtonCompact mode="outlined" title={t("Items.collection.emptyCart")} icon="Delete" onClick={() => setIsEmptyCartModalOpen(true)}>
              {t("Items.collection.emptyCart")}
            </ButtonCompact>
          </Stack>
          <Stack alignItems="center" gap={"L"}>
            <CreateReportDropdown onChange={handleSelectReportType} reportsFilter={[REPORT_TYPES.DailyGlobal, REPORT_TYPES.WeeklyGlobal]} />
            {reportType && (
              <Stack justifyContent="space-between" gap="S" alignItems="center">
                <ButtonIcon iconSize="XL" mode="stateless" icon="Calendar" color="neutral" onClick={showCalendar} />
                  <>
                   {scheduleDate && <Text data-testid="eventDate" bold truncate>
                      {scheduleDate?.toDateString()} {time}
                    </Text>}
                    <ScheduleTimeComponent
                      data-testid="ItemNavigator-ShowCalendar"
                      showCalendar={showCalendar}
                      hideCalendar={hideCalendar}
                      isOpen={isCalendarOpen}
                      scheduleDate={scheduleDate}
                      setScheduleDate={setScheduleDate}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      endDate={endDate}
                      lang={i18n.language}
                      blockPastDays={0}
                      width="auto"
                    />
                  </>
                
              </Stack>
            )}
            <ButtonCompact
              mode="contained"
              color="accent"
              title={t("Items.report.create")}
              disabled={!reportType || !scheduleDate || !itemsCollectionSelected?.length}
              onClick={handleCreateReport}
            >
              {t("Items.report.create")}
            </ButtonCompact>
          </Stack>
        </Stack>
        <Stack direction="column" gap="S">
          {collectedItems.length > 0 &&
            initRegions.length > 0 &&
            initRegions.map((r) => {
              const regionItems = collectedItems.filter((item) => item.region === r.region);

              return (
                <Box marginBottom="S" key={r.region}>
                  <Box marginVertical="XS">
                    <Text bold>{getTranslatedTag(r.region, "region")}</Text>
                  </Box>
                  {regionItems.length ? (
                    regionItems.map((item) => {
                      const endDate = getEndDate(item);

                      return (
                        <ItemContainer selected={item?.selected} style={{ marginBottom: `${Spacing.XS}px` }} key={item.id}>
                          <Stack alignItems="center" gap={"XS"} justifyContent="space-between" direction="row">
                            <Box marginVertical="XXS" paddingVertical="XXS">
                              <RadioButton
                                id={item.id}
                                label={item.title}
                                name={r.region}
                                onClick={handleSelectCollecedItem}
                                value={item.id}
                                checked={item.selected}
                              />
                            </Box>
                            <DataBox paddingLeft="S">
                              <Text small>{`${getDateWithoutTimezone(item.start_date_of_event).toDateString()}`}</Text>
                              {endDate && <Text small>{endDate}</Text>}
                            </DataBox>
                          </Stack>
                        </ItemContainer>
                      );
                    })
                  ) : (
                    <ItemContainer selected={false}>
                      <Stack alignItems="center" gap={"XS"}>
                        <Icon variant="Warning" color="low" />
                        <Text>{t("Items.collection.list.empty")}</Text>
                      </Stack>
                    </ItemContainer>
                  )}
                </Box>
              );
            })}
        </Stack>
      </Modal>
      <ConfirmationModal
        isOpen={isEmptyCartModalOpen}
        setIsOpen={setIsEmptyCartModalOpen}
        action={handleEmptyCart}
        title={t("Items.collection.empty.title")}
        subtitle={t("Items.collection.empty.subtitle")}
        yesColor="error"
      />
    </>
  );
};

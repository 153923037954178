import { useAuth0 } from "@auth0/auth0-react";
import i18next from "i18next";
import React, { Ref, useCallback, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSearchParam } from "../hooks/useSearchParam";
import { getPreferredLanguage } from "../utilities/preferencesUtilities";
import { getIsEmbedded, getIsEmbeddedInMobile } from "../utilities/queryUtils";
import { fetchUserRoles, getDataFromBE, getUserData, getUserDataById, getUserPrefs } from "../utilities/userUtils";
import { TrackingProvider } from "../context/TrackingContext";
import { useUserData } from "../hooks/useUserData";
import { getEmail, getUserName, isUserNameFilled } from "../utilities/utils";
import { Toolbar } from "./Navigation/Toolbar/Toolbar";
import { ApplicationContainer, HomePageContainer, HomePageContent, LoadingWrapper } from "./styled";
import { useTagsAndRegions } from "../hooks/useTagsAndRegions";
import { useReportTypes } from "../hooks/useReportTypes";
import { LANGUAGE_KEY } from "../constants/generalSettings";
import { Navigation } from "./Navigation/Navigation";
import { useSchedule } from "../hooks/useSchedule";
import { LoadingIndicator } from "@secuis/ccp-react-components";
import { useAppErrorNotification } from "../hooks/useAppErrorNotification";
import { usePreloadMetadata } from "../store/items/ItemsHooks";
import { toolbarActions } from "../store/toolbar/ToolbarSlice";
import { useAppDispatch } from "../store";

const AuthenticatedApp: React.FC<{ scrollRef?: Ref<HTMLDivElement> }> = ({ scrollRef }) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useAppErrorNotification();
  const { setUserId, userId, userToken, setUserData, setPreferences, setLang, userData } = useUserData();
  const { uploadReportsTypes } = useReportTypes();
  const { uploadSchedules } = useSchedule();
  const { uploadTags } = useTagsAndRegions();
  const a = useSearchParam("a");
  const c = useSearchParam("c");
  const { pathname } = useLocation();
  usePreloadMetadata();

  const fetchData = useCallback(async () => {
    try {
      const tags = await getDataFromBE(userToken, "/tags");
      const schedules = await getDataFromBE(userToken, "/report-types?product_id=1");
      uploadSchedules(schedules.data);
      uploadReportsTypes(schedules.data);
      uploadTags(tags.data);

      const userData = user?.email ? await getUserData(userToken, user.email) : await getUserDataById(userToken, userId);

      if (userData) {
        setUserId(userData.id);
        const preferences = await getUserPrefs(userData.id, userToken);
        setPreferences(preferences);
        if (preferences[LANGUAGE_KEY]) {
          setLang(preferences[LANGUAGE_KEY]);
        }

        const language = getPreferredLanguage(userData.preferences);
        if (language && language !== i18next.language) {
          i18next.changeLanguage(language);
        }

        const userProperties = {
          name: getUserName(userData),
          isEmbedded: getIsEmbedded(a),
          isEmbeddedInMobile: getIsEmbeddedInMobile(a),
          isUserNameFilled: isUserNameFilled(userData),
          roles: await fetchUserRoles(userData.id, userToken),
          email: getEmail(userData),
          isLoaded: true,
        };
        setUserData(userProperties);
      }

      if (c === "settings") {
        navigate("/settings", { replace: true });
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a, c, user?.email, userId, userToken]);

  useEffect(() => {
    return () => {
      dispatch(toolbarActions.setToolbar({
        visible: false,
        isOpen: false,
      }))
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pathname])

  useEffect(() => {
    if (userToken) {
      fetchData();
    } else if (c === "settings") {
      navigate("/settings", { replace: true });
    }
  }, [userToken, c]);

  const pathsWithScroll = ["/", "//", "/reports"];

  return (
    <TrackingProvider>
      {userData?.isLoaded ? (
        <ApplicationContainer data-testid="auth-app">
          <Navigation />
          <HomePageContainer shouldShowScroll={!pathsWithScroll.includes(pathname)} ref={scrollRef}>
            <HomePageContent>
              <Outlet />
            </HomePageContent>
          </HomePageContainer>
          <Toolbar />
        </ApplicationContainer>
      ) : (
        <LoadingWrapper>
          <LoadingIndicator size="L" />
        </LoadingWrapper>
      )}
    </TrackingProvider>
  );
};

export default AuthenticatedApp;

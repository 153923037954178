import { ButtonCompact, ButtonIcon, Dropdown, Stack, Text } from "@secuis/ccp-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectItemsCollection, selectMetadata } from "../../../../store/items/ItemsSelectors";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { startOfTomorrow } from "date-fns";
import { ScheduleTimeComponent } from "../../../DatePicker/ScheduleTimeComponent";
import { useScheduleDate } from "../../../../hooks/useScheduleDate";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { itemsActions } from "../../../../store/items/ItemsSlice";
import { toolbarActions } from "../../../../store/toolbar/ToolbarSlice";
import { RegionBrief } from "./RegionBrief";
import { getLocalDate } from "../../../../utilities/dateUtils";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { usePostSendoutMutation } from "../../../../store/sendouts/SendoutsApi";
import { ToolbarContentWrapper, ToolbarFooter, ToolbarFooterWrapper } from "../Toolbar.styles";
import { SendoutPreferences } from "./CollectedBriefs.style";
import { SENDOUT_TYPE, SendoutType } from "../../../../models/SendoutModel";
import { useNavigate } from "react-router-dom";

export const CollectedBriefs = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { region_daily } = useAppSelector(selectMetadata);
  const collectedItems = useAppSelector(selectItemsCollection);
  const [isEmptyCartModalOpen, setIsEmptyCartModalOpen] = useState<boolean>(false);
  const [reportType, setReportType] = useState<SendoutType>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(startOfTomorrow());
  const [endDate, setEndDate] = useState<Date | null>(startOfTomorrow());
  const { scheduleDate, setScheduleDate } = useScheduleDate(reportType);
  const [postSendout] = usePostSendoutMutation();
  const { toast } = useContext(NotificationsContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    if(collectedItems.length === 0) {
      dispatch(toolbarActions.setToolbar({ isOpen: false }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectedItems.length]);
  
  const handleSelectReportType = (value: string) => {
    setReportType(value as SendoutType);
  };

  const hideCalendar = () => {
    setIsCalendarOpen(false);
  };

  const showCalendar = () => {
    setIsCalendarOpen(true);
  };

  const handleEmptyCart = () => {
    dispatch(itemsActions.emptyCollection());
    setIsEmptyCartModalOpen(false);
  };

  const handleCreateSendout = () => {
    postSendout({
      schedule: scheduleDate,
      type: reportType,
      item_ids: collectedItems.map((item) => item.id),
    })
    .unwrap()
    .then(() => {
      dispatch(itemsActions.emptyCollection());
      toast({
        title: t("Items.pickToSendout.toast.success"),
        type: NotificationTypes.Confirmation,
        icon: "Success",
      });
      navigate("/sendouts", { replace: true });
    })
    .catch(() => true);
  };

  return (
    <>
      <ToolbarContentWrapper>
        <Stack direction="column" flex={1}>
          {
            region_daily.length > 0 && 
            region_daily.map((region) => <RegionBrief region={region} />)
          }
        </Stack>
      </ToolbarContentWrapper>
      <ToolbarFooter>
        <ToolbarFooterWrapper>
          <SendoutPreferences>
            <Text>{t("Briefs.collection.sendOutPreferences")}</Text>
            <Dropdown
              value={reportType}
              placeholder={t("CreateReport.dropdown.createReport")}
              sheetTitle={t("CreateReport.dropdown.createReport")}
              title={t("CreateReport.dropdown.createReport")}
              onChange={handleSelectReportType}
              options={[
                { value: "daily", title: t(`Sendout.${SENDOUT_TYPE.daily}.title`) }, 
                { value: "weekly", title: t(`Sendout.${SENDOUT_TYPE.weekly}.title`) }
              ]}
              sheetCancelLabel="Cancel"
            />
            <Stack direction="row" flex={1} justifyContent="space-between" alignItems="center">
              <Stack alignItems="center" gap="XS">
                <ButtonIcon iconSize="XL" mode="stateless" icon="Calendar" color="neutral" onClick={showCalendar}/>
                <Text data-testid="eventDate" bold truncate>{scheduleDate ? getLocalDate(scheduleDate, i18n.language) : t("CreateReport.schedule.selectDate")}</Text>
              </Stack>
              <ScheduleTimeComponent
                data-testid="ItemNavigator-ShowCalendar"
                showCalendar={showCalendar}
                hideCalendar={hideCalendar}
                isOpen={isCalendarOpen}
                scheduleDate={scheduleDate}
                setScheduleDate={setScheduleDate}
                startDate={startDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
                lang={i18n.language}
                blockPastDays={0}
                width="auto"
                placement="top"
              />
            </Stack>
            <Stack justifyContent="space-between">
              <ButtonCompact
                mode="outlined"
                title={t("Items.collection.emptyCart")}
                icon="Delete"
                onClick={() => setIsEmptyCartModalOpen(true)}>{t("Items.collection.emptyCart")}
              </ButtonCompact>
              <ButtonCompact
                disabled={!reportType || !scheduleDate}
                color="accent"
                mode="contained"
                title={t("Briefs.collection.sendOut")}
                onClick={handleCreateSendout}>{t("Briefs.collection.sendOut")}
              </ButtonCompact>
            </Stack>
          </SendoutPreferences>
        </ToolbarFooterWrapper>
      </ToolbarFooter>
      <ConfirmationModal
        isOpen={isEmptyCartModalOpen}
        setIsOpen={setIsEmptyCartModalOpen}
        action={handleEmptyCart}
        title={t("Items.collection.empty.title")}
        subtitle={t("Briefs.collection.empty.subtitle")}
        yesColor="error"
      />
    </>
  );
}
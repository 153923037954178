import { useCallback, useEffect, useState } from "react";
import { useSchedule } from "./useSchedule";

export const useScheduleDate = (reportType: string ) => {
  const { getSchedule, schedules } = useSchedule();
  const [scheduleDate, setScheduleDate] = useState<Date>();

  const fetchDefaultSchedule = useCallback(
    () => {
      const nextSchedule = getSchedule(reportType);
      if (nextSchedule) {
        setScheduleDate(new Date(nextSchedule));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportType],
  );

  useEffect(() => {
    if (schedules && reportType) {
      fetchDefaultSchedule();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, schedules]);
  
  return { scheduleDate, setScheduleDate };
};
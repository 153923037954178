import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utilities/baseQuery";
import { Sendout, SendoutModel, SendoutType } from "../../models/SendoutModel";
import { SendoutFiltersModel } from "../../models/SendoutFiltersModel";

export interface FetchSendoutsParams {
  page?: number;
  limit?: number;
  filters?: SendoutFiltersModel;
}

export interface FetchSendoutsMetaResponse {
  meta: {
    count: number;
    limit: number;
    page: number;
  };
}

export const sendoutsApi = createApi({
  reducerPath: "sendoutsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Sendouts"],
  endpoints: (builder) => ({
    getSendouts: builder.query<{ data: Sendout[] } & FetchSendoutsMetaResponse, FetchSendoutsParams>({
      query: ({ page, limit = 20, filters }) => ({
        url: "sendouts",
        params: {
          page,
          limit,
          ...filters,
        },
      }),
    }),
    postSendout: builder.mutation<SendoutModel, { schedule: Date; type: SendoutType; item_ids: string[]; }>({
      query: (sendout) => ({
        url: "sendouts",
        method: "POST",
        body: sendout,
      }),
    }),
  }),
});

export const {
  useLazyGetSendoutsQuery,
  usePostSendoutMutation,
} = sendoutsApi;

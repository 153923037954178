import React from "react";
import { selectItemsCollection } from "../../../../store/items/ItemsSelectors";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Box, ButtonText, CountIndicator, Icon, Separator, Stack, Text } from "@secuis/ccp-react-components";
import { getTranslatedTag } from "../../../../utilities/tagsUtils";
import { getEndDate } from "../../../../utilities/Item.helpers";
import { BriefContainer } from "./CollectedBriefs.style";
import { format } from "date-fns";
import { getDateWithoutTimezone } from "../../../../utilities/dateUtils";
import { itemsActions } from "../../../../store/items/ItemsSlice";

const MAX_BRIEFS = 3;

interface RegionBriefProps {
  region: string;
};

export const RegionBrief = ({ region }: RegionBriefProps) => {
  const collectedItems = useAppSelector(selectItemsCollection);
  const dispatch = useAppDispatch();
  const regionBriefs = collectedItems.filter((item) => item.region === region);

  const handleReleaseBrief = (id: string) => {
    dispatch(itemsActions.releaseItem(id));
  };
  
  return (
    regionBriefs.length > 0 && 
      <Stack key={region} direction="column" justifyContent="space-between">
        <Box pv="S">
          <Separator mb="S"/>
          <Stack justifyContent="space-between">
            <Text small>{getTranslatedTag(region, "region")}</Text>
            {
              regionBriefs.length === MAX_BRIEFS 
                ? <Icon variant="Success" color="success" /> 
                : <CountIndicator count={regionBriefs.length} position="relative" color={regionBriefs.length > 3 ? "high" : "accent"} />
            }
          </Stack>
        </Box>
        {
          regionBriefs.map((brief) => {
            const endDate = getEndDate(brief);

            return ( 
              <BriefContainer key={brief.id}>
                <Stack gap={'XS'} justifyContent="flex-end" direction="column">
                  <Stack justifyContent="flex-end">
                    <Text micro>{`${format(getDateWithoutTimezone(brief.start_date_of_event), "iii, d MMM yyyy")}`}{endDate ? ` - ${endDate}` : ""}</Text>
                  </Stack>
                  <Stack justifyContent="space-between">
                    <Text micro truncate>{brief.title}</Text>
                    <ButtonText icon="Delete" color="error" iconSize="S" onClick={() => handleReleaseBrief(brief.id)} />
                  </Stack>
                </Stack>
              </BriefContainer>
            );
          })
        }
    </Stack>
  );
}
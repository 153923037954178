import { Box, ButtonCompact, EmptyState, LoadingIndicator, Stack, Title } from "@secuis/ccp-react-components";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectBriefs, selectBriefsFilters, selectBriefsPage, selectBriefsShowMore, selectBriefsStatus } from "../../store/briefs/BriefsSelectors";
import { CenteredWrapper, ItemsWrapper, LoadingWrapper } from "../Items/List/styled";
import ItemPreviewModal from "../Items/Preview/ItemPreviewModal";
import { ListContainer } from "../Reports/List/ReportsList.styles";
import { Sendout } from "./Sendout";
import { toolbarActions } from "../../store/toolbar/ToolbarSlice";
import { useLazyGetSendoutsQuery } from "../../store/sendouts/SendoutsApi";
import { selectSendouts, selectSendoutsFilters, selectSendoutsPage, selectSendoutsShowMore, selectSendoutsStatus } from "../../store/sendouts/SendoutsSelectors";

export const SendoutsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sendoutsList = useAppSelector(selectSendouts);
  const sendoutsStatus = useAppSelector(selectSendoutsStatus);
  const showMore = useAppSelector(selectSendoutsShowMore);
  const page = useAppSelector(selectSendoutsPage);
  const filters = useAppSelector(selectSendoutsFilters);
  const [getSendouts] = useLazyGetSendoutsQuery();

  const fetchSendouts = useCallback(
    (page: number) => {
      getSendouts({ page, filters });
    },
    [getSendouts, filters]
  );

  useEffect(() => {
    dispatch(toolbarActions.setToolbar({
      visible: true,
      contentType: "filters",
      storeType: "sendouts",
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSendouts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ItemsWrapper>
      <Box mb="S">
        <Title>{t("Sendouts.list.Title")}</Title>
      </Box>
      <Stack >
        <ButtonCompact mode="outlined" onClick={() => fetchSendouts(1)} icon="Reload">
          {t("reports.list.refresh")}
        </ButtonCompact>
      </Stack>
        {sendoutsStatus === "fulfilled" && sendoutsList.length > 0 && (
          <ListContainer id="scrollableItemsDiv" mt="XS">
            <InfiniteScroll
              dataLength={sendoutsList.length}
              next={() => fetchSendouts(page + 1)}
              hasMore={showMore}
              loader={
                <LoadingWrapper>
                  <LoadingIndicator size="L" />
                </LoadingWrapper>
              }
              scrollableTarget="scrollableItemsDiv"
            >
              {sendoutsList.map((sendout) => (
                <Sendout key={sendout.id} sendoutData={sendout} />
              ))}
            </InfiniteScroll>
          </ListContainer>
        )}
        {sendoutsStatus === "pending" && (
          <CenteredWrapper>
            <LoadingIndicator size="L" />
          </CenteredWrapper>
        )}
        {sendoutsStatus === "fulfilled" && !sendoutsList.length && (
          <CenteredWrapper>
            <EmptyState icon="Risk" title={t("Items.list.emptyState")} />
          </CenteredWrapper>
        )}
        {sendoutsStatus === "rejected" && !sendoutsList.length && (
          <CenteredWrapper>
            <EmptyState icon="Warning" title={t("Items.list.errorState")} />
          </CenteredWrapper>
        )}
      <ItemPreviewModal isBrief />
    </ItemsWrapper>
  );
};

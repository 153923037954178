import { Box, ButtonCompact, EmptyState, LoadingIndicator, Stack, Title } from "@secuis/ccp-react-components";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectBriefs, selectBriefsFilters, selectBriefsPage, selectBriefsShowMore, selectBriefsStatus } from "../../store/briefs/BriefsSelectors";
import { CenteredWrapper, ItemsWrapper, LoadingWrapper } from "../Items/List/styled";
import ItemPreviewModal from "../Items/Preview/ItemPreviewModal";
import { ListContainer } from "../Reports/List/ReportsList.styles";
import { Brief } from "./Brief";
import { useLazyGetBriefsQuery } from "../../store/briefs/BriefsApi";
import { toolbarActions } from "../../store/toolbar/ToolbarSlice";

export const BriefsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { filteredBriefs } = useAppSelector(selectBriefs);
  const briefsStatus = useAppSelector(selectBriefsStatus);
  const showMore = useAppSelector(selectBriefsShowMore);
  const page = useAppSelector(selectBriefsPage);
  const filters = useAppSelector(selectBriefsFilters);
  const [getBriefs] = useLazyGetBriefsQuery();

  const fetchBriefs = useCallback(
    (page: number) => {
      getBriefs({ page, filters });
    },
    [getBriefs, filters]
  );

  useEffect(() => {
    dispatch(toolbarActions.setToolbar({
      visible: true,
      contentType: "filters",
      storeType: "briefs",
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBriefs(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ItemsWrapper>
      <Box mb="S">
        <Title>{t("Briefs.list.Title")}</Title>
      </Box>
      <Stack >
        <ButtonCompact mode="outlined" onClick={() => fetchBriefs(1)} icon="Reload">
          {t("reports.list.refresh")}
        </ButtonCompact>
      </Stack>
        {briefsStatus === "fulfilled" && filteredBriefs.length > 0 && (
          <ListContainer id="scrollableItemsDiv" mt="XS">
            <InfiniteScroll
              dataLength={filteredBriefs.length}
              next={() => fetchBriefs(page + 1)}
              hasMore={showMore}
              loader={
                <LoadingWrapper>
                  <LoadingIndicator size="L" />
                </LoadingWrapper>
              }
              scrollableTarget="scrollableItemsDiv"
            >
              {filteredBriefs.map((brief) => (
                <Brief key={brief.id} briefData={brief} />
              ))}
            </InfiniteScroll>
          </ListContainer>
        )}
        {briefsStatus === "pending" && (
          <CenteredWrapper>
            <LoadingIndicator size="L" />
          </CenteredWrapper>
        )}
        {briefsStatus === "fulfilled" && !filteredBriefs.length && (
          <CenteredWrapper>
            <EmptyState icon="Risk" title={t("Items.list.emptyState")} />
          </CenteredWrapper>
        )}
        {briefsStatus === "rejected" && !filteredBriefs.length && (
          <CenteredWrapper>
            <EmptyState icon="Warning" title={t("Items.list.errorState")} />
          </CenteredWrapper>
        )}
      <ItemPreviewModal isBrief />
    </ItemsWrapper>
  );
};

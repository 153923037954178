import React, { useState } from "react";
import { ButtonsBox, ItemContainer } from "../Items/List/styled";
import { Parameter } from "../Items/List/Parameter";
import { Badge, ButtonCompact, Stack} from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { SendoutParameters } from "./Sendout.styles";
import { SENDOUT_TYPE, Sendout as SendoutModel } from "../../models/SendoutModel";
import { getLocalDate } from "../../utilities/dateUtils";
import { getColor } from "../../utilities/sectionUtils";

interface SendoutProps {
  sendoutData: SendoutModel;
}

export const Sendout = ({ sendoutData }: SendoutProps) => {
  const { t, i18n } = useTranslation();
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const onSelectSendout = () => {
    setIsSelected(!isSelected);
  };

  return (
      <ItemContainer selected={isSelected} onClick={onSelectSendout}>
        <Stack direction="row">
          <SendoutParameters>
            <Parameter label={t("Filter.status")} small>
              <Badge label={t(`Section.status.${sendoutData.status}`)} mode="outlined" color={getColor(sendoutData.status)} />
            </Parameter>
            <Parameter label={t("Sendout.label")} medium>{getLocalDate(sendoutData.schedule, i18n.language)}</Parameter>
            <Parameter label={t("Briefs.dropdown.createSendout")} small>{t(`reports.${SENDOUT_TYPE[sendoutData.type]}.title`)}</Parameter>
          </SendoutParameters>
          <ButtonsBox>
            <ButtonCompact mode="contained" color="accent">{t("Items.preview")}</ButtonCompact>
          </ButtonsBox>
        </Stack>
      </ItemContainer>
  );
};

import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { ButtonIcon, Datepicker, Input, Link, Overlay, Spacing, useClickOutside } from "@secuis/ccp-react-components";
import { addHours, addMinutes, format, getHours, getMinutes, isToday, startOfDay } from "date-fns";
import { Content, ScheduleTime, TimeInputs, Wrapper, TimeSeparator, WidthType } from "./ScheduleTimeComponent.styles";
import { useTranslation } from "react-i18next";

interface ScheduleTimeComponentProps {
  showCalendar: () => void;
  hideCalendar: () => void;
  isOpen: boolean;
  align?: string;
  scheduleDate: Date;
  setScheduleDate: (date: Date) => void;
  startDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  endDate: Date;
  lang: string;
  blockPastDays?: number;
  width?: WidthType;
  placement?: "top" | "bottom";
}

export const ScheduleTimeComponent: FC<ScheduleTimeComponentProps> = ({
  showCalendar,
  hideCalendar,
  isOpen,
  align = "right",
  scheduleDate,
  setScheduleDate,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  lang,
  blockPastDays = 0,
  width = '100%' as WidthType,
  placement = "bottom"
}) => {
  const { t } = useTranslation();
  const elemRef = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(null);
  const [offsetRight, setOffsetRight] = useState(null);

  const [hours, setHours] = useState(scheduleDate ? format(scheduleDate, "HH") : "00");
  const [invalidHours, setInvalidHours] = useState(false);
  const [invalidMinutes, setInvalidMinutes] = useState(false);
  const [minutes, setMinutes] = useState(scheduleDate ? format(scheduleDate, "mm") : "00");

  const cleanupDates = (date) => {
    if (date) {
      setMinutes(format(date, "mm"));
      setHours(format(date, "HH"));
      setInvalidHours(false);
      setInvalidMinutes(false);
      setStartDate(date);
      setEndDate(date);
    }
  };

  const clearValues = () => {
    cleanupDates(scheduleDate);
    hideCalendar();
  };

  const { nodeRef } = useClickOutside<HTMLDivElement>(clearValues);
  
  const validateMinutes = (value) => {
    const isMinutesFromPast = isToday(startDate) && getHours(new Date()) === Number(hours) ? getMinutes(new Date()) > Number(value) : false;
    setInvalidMinutes(isNaN(Number(value)) || Number(value) > 59 || isMinutesFromPast);
  };

  const validateHours = (value) => {
    const isHourFromPast = isToday(startDate) ? getHours(new Date()) > Number(value) : false;
    setInvalidHours(isNaN(Number(value)) || Number(value) > 24 || isHourFromPast);
  };

  useEffect(() => {
    if (isOpen) {
      const { bottom, left, right, top } = elemRef.current.getBoundingClientRect();
      const { height } = nodeRef.current.getBoundingClientRect();
      placement === "bottom" ? setOffsetTop(Math.round(bottom + Spacing.XXS)) : setOffsetTop(Math.round(top - height));
      align === "left" ? setOffsetLeft(Math.round(left)) : setOffsetRight(Math.round(window.innerWidth - right));
    }
  }, [isOpen, align]);

  useEffect(() => {
    validateHours(hours);
    if (getHours(new Date()) === Number(hours)) {
      validateMinutes(minutes);
    }
  }, [startDate]);

  useEffect(() => {
    validateMinutes(minutes);
  }, [hours, startDate]);

  const handleHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    setHours(input.value);
    validateHours(input.value);
  };

  const handleMinutesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    setMinutes(input.value);
    validateMinutes(input.value);
  };

  const handleSave = () => {
    const finalDate = addMinutes(addHours(startOfDay(startDate), Number(hours)), Number(minutes));

    setScheduleDate(finalDate);
    cleanupDates(finalDate);
    hideCalendar();
  };

  return (
    <Wrapper $width={width}>
      <div ref={elemRef}>
        <Link
          data-testid="EditReport"
          onClick={() => {
            showCalendar();
          }}
        >
          {t("NewReport.edit")}
        </Link>
      </div>
      {isOpen && (
        <Overlay>
          <Content ref={nodeRef} offsetTop={offsetTop} offsetLeft={offsetLeft} offsetRight={offsetRight} role="presentation">
            <Datepicker
              mode="singleMonth"
              startDate={startDate}
              endDate={endDate}
              isDaySelection
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              blockPastBeforeDays={blockPastDays}
              hideCalendar={() => {}}
              saveButtonLabel={t("Filter.date")}
              languageCode={lang}
            />
            <ScheduleTime>
              <TimeInputs>
                <Input inputMode="numeric" maxLength={2} value={hours.toString()} onChange={handleHoursChange} invalid={invalidHours}></Input>
                <TimeSeparator>:</TimeSeparator>
                <Input inputMode="numeric" maxLength={2} value={minutes.toString()} onChange={handleMinutesChange} invalid={invalidMinutes}></Input>
              </TimeInputs>
              <ButtonIcon
                data-testid="SaveScheduleTime"
                icon="ValidatedCheck"
                mode="contained"
                onClick={handleSave}
                disabled={invalidHours || invalidMinutes}
              />
            </ScheduleTime>
          </Content>
        </Overlay>
      )}
    </Wrapper>
  );
};

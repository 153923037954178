import { Breakpoints, ButtonText, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRoles } from "../../hooks/useRoles";
import { useAppSelector } from "../../store";
import { selectNotSeenReportsCount } from "../../store/reports/ReportsSelectors";
import { Container } from "./Navigation.styles";
import NavigationLink from "./NavigationLink";
import ContactUsButton from "./ContactUsButton";

export const Navigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { shouldShowContactUs, canSeeBriefs, cantSeeItems } = useRoles();
  const notViewedReportsCount = useAppSelector(selectNotSeenReportsCount);
  const [isExpanded, setIsExpanded] = useState(false);

  const isTablet = useHasMaxWidth(Breakpoints.M);
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const isDesktop = !isMobile && !isTablet;

  return (
    <Container isExpanded={isExpanded}>
      {isDesktop && (
        <Stack data-testid="desktop-navigation" justifyContent="space-between" mb="L">
          {isExpanded && (
            <Text data-testid="navigation-text" bold>
              {t("Common.navigation")}
            </Text>
          )}
          <ButtonText
            data-testid="navigation-icon"
            iconSize="L"
            icon={isExpanded ? "DoubleArrowBack" : "DoubleArrowForward"}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </Stack>
      )}
      <Stack
        direction={isMobile ? "row" : "column"}
        justifyContent={isMobile ? "space-evenly" : "flex-start"}
        mb={isMobile ? "M" : "0"}
        gap={isMobile ? "S" : "L"}
        flex={1}
      >
        <NavigationLink
          label={t("Menu.item1")}
          icon="Report"
          to="/reports"
          isExpanded={isExpanded || isMobile}
          counterValue={notViewedReportsCount}
          isActive={pathname === "/" || pathname === "//" || pathname.startsWith('/createReport')}
        />
        { process.env.REACT_APP_COLLECT_BRIEFS === "true" && <NavigationLink
          label={t("Menu.item7")}
          icon="Send"
          to="/sendouts"
          isExpanded={isExpanded || isMobile}
          isActive={!!pathname.match("sendouts")}
        /> }
        {canSeeBriefs &&
          <NavigationLink 
            label={t("Menu.item6")}
            icon="Document"
            to="/briefs"
            isExpanded={isExpanded || isMobile}
            isActive={!!pathname.match("briefs")}
          />
        }
        {cantSeeItems && 
          <NavigationLink 
            label={t("Menu.item5")}
            icon="Document"
            to="/items"
            isExpanded={isExpanded || isMobile}
            isActive={pathname === "/items" || pathname === "/createItem" || pathname.startsWith('/editItem/')}
          />
        }
        <NavigationLink label={t("Menu.item2")} icon="QuestionAskHelp" to="/faq" isExpanded={isExpanded || isMobile} />
        <NavigationLink label={t("Menu.item3.desktop")} icon="Settings" to="/settings" isExpanded={isExpanded || isMobile} />
        {shouldShowContactUs && isMobile && <ContactUsButton isSmallScreen={isTablet} displayLabel={isExpanded || isMobile} />}
      </Stack>

      {shouldShowContactUs && !isMobile && <ContactUsButton isSmallScreen={isTablet} displayLabel={isExpanded || isMobile} />}
    </Container>
  );
};

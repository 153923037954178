
import { IRootState } from "../../store";

export const selectSendouts = ({ sendouts: { data } }: IRootState) => data;

export const selectSendoutsStatus = ({ sendouts: { status } }: IRootState) => status;

export const selectSendoutsShowMore = ({ sendouts: { showMore } }: IRootState) => showMore;

export const selectSendoutsPage = ({ sendouts: { page } }: IRootState) => page;

export const selectSendoutsFilters = ({ sendouts: { filters } }: IRootState) => filters;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemFiltersModel } from "../../models/ItemFiltersModel";
import { ItemMetadataModel } from "../../models/ItemMetadataModel";
import { CollectItem, Item } from "../../models/ItemModel";
import { FetchStatus } from "../../models/ReportModel";
import { ViewMode } from "../../utilities/types";
import { itemsApi } from "./ItemsApi";

export type ItemsState = {
  viewMode: ViewMode;
  data: Item[];
  status: FetchStatus;
  page: number;
  showMore: boolean;
  filters?: ItemFiltersModel;
  preview: {
    itemId?: string;
    item?: Item;
  };
  collection?: CollectItem[];
  metadata: ItemMetadataModel;
};

export const initialState: ItemsState = {
  viewMode: ViewMode.long,
  data: [],
  status: "idle",
  page: 1,
  showMore: false,
  filters: {},
  preview: {},
  collection: [],
  metadata: {
    countries: [],
    organizations: [],
    region_daily: [],
    region_weekly: [],
    region_v3: [],
    sectors: [],
    threat_actors: [],
    threat_levels: [],
    threat_risk_categories: [],
  },
};

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setViewMode: (state, { payload }: PayloadAction<ViewMode>) => {
      state.viewMode = payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setPreview: (state, { payload }: PayloadAction<{ itemId: string; item?: Item }>) => {
      state.preview.itemId = payload.itemId;
      state.preview.item = payload.item;
    },
    clearPreview: (state) => {
      state.preview = {};
    },
    collectItem: (state, action) => {
      const { id, region, title, start_date_of_event, end_date_of_event, threat_risk_categories, details, item_status } = state.data.find(
        (item) => item.id === action.payload
      );
      state.collection = [...state.collection, { id, region, title, start_date_of_event, end_date_of_event, threat_risk_categories, details, item_status }];
    },
    releaseItem: (state, action) => {
      state.collection = state.collection.filter((c) => c.id !== action.payload);
    },
    checkCollectItem: (state, action) => {
      const editedItems = state.collection.filter((item) => item.region === action.payload.region).map((i) => ({ ...i, selected: i.id === action.payload.id }));
      state.collection = [...state.collection.filter((item) => item.region !== action.payload.region), ...editedItems];
    },
    emptyCollection: (state) => {
      state.collection = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(itemsApi.endpoints.getItems.matchPending, (state, action) => {
        state.status = action.meta.arg.originalArgs.page === 1 ? action.meta.requestStatus : state.status;
      })
      .addMatcher(itemsApi.endpoints.getItems.matchRejected, (state, action) => {
        state.status = action.meta.requestStatus;
        state.data = action.meta.arg.originalArgs.page === 1 ? [] : state.data;
      })
      .addMatcher(itemsApi.endpoints.getItems.matchFulfilled, (state, action) => {
        if (action.payload?.data) {
          state.data = action.meta.arg.originalArgs.page === 1 ? action.payload.data : [...state.data, ...action.payload.data];
          state.page = action.payload.meta.page ?? state.page;
          state.showMore = action.payload.meta.count ? action.payload.meta.count === action.payload.meta.limit : false;
          state.status = action.meta.requestStatus;
        }
      })
      .addMatcher(itemsApi.endpoints.deleteItem.matchPending, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(itemsApi.endpoints.deleteItem.matchRejected, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(itemsApi.endpoints.deleteItem.matchFulfilled, (state, action) => {
        state.data = state.data.filter(({ id }) => id !== action.meta.arg.originalArgs.id);
        state.status = action.meta.requestStatus;
      })
      .addMatcher(itemsApi.endpoints.getMetadata.matchFulfilled, (state, action) => {
        state.metadata = {
          ...state.metadata,
          ...action.payload,
        };
      });
  },
});

export const itemsActions = itemsSlice.actions;

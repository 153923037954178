import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const BriefContainer = styled(Stack).attrs({
  direction: "column",
  pv: "XXS",
  ph: "XS",
  mb: "XXS"
})`
  background-color: ${Palette.Navy750};
  border-radius: 4px;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${Palette.Navy650};
    }
  }
`;

export const ButtonsWrapper = styled(Stack).attrs({
  direction: "column",
  alignItems: "center",
  gap: "S",
})`
  margin-top: ${Spacing.XXL}px;
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  
  @media (max-width: ${Breakpoints.XS}) {
    margin-top: ${Spacing.S}px;
  }
`;

export const StickyFooter = styled(Stack).attrs({
  direction: "column",
})`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
`;

export const SendoutPreferences = styled(Stack).attrs({
  direction: "column",
  gap: "M",
})`
  width: 100%;
`;
import React, { ReactNode } from "react";
import { ParameterBox } from "./styled";
import { Color, Icon, IconType, Stack, Text } from "@secuis/ccp-react-components";

interface IParameterProps {
  icon?: IconType;
  iconColor?: Color;
  label: string;
  children: string | ReactNode;
  noWrapper?: boolean;
  disabled?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  defWidth?: boolean;
  truncate?: boolean;
  item?: boolean;
}

export const Parameter = ({
  children,
  icon,
  iconColor = "neutral",
  label,
  truncate = true,
  noWrapper = false,
  disabled = false,
  small = false,
  medium = false,
  large = false,
  defWidth = true,
  item = false,
}: IParameterProps) => {
  return (
    <ParameterBox $small={small} $medium={medium} $large={large} $defWidth={defWidth} $item={item}>
      <Text uppercase color="neutral" small>
        {label}
      </Text>
      <Stack alignItems="center" gap="XXS">
        {icon && <Icon variant={icon} color={iconColor} disabled={disabled} />}
        {!noWrapper && (
          <Text color="white" title={children as string} truncate={truncate} small>
            {children}
          </Text>
        )}
        {noWrapper && children}
      </Stack>
    </ParameterBox>
  );
};
